import React, { useEffect, useState } from 'react';
import './Home.css'; // Import CSS file

const data = [
    { text: "DRIFTERS OS - CRITICAL UPDATE REQUIRED\n\n" },
    { text: "WARNING: 0xDEADSPACE\n", yellow:true },
    { text: "Current activity levels unsustainable." },
    { text: "Full system recalibration required.\n\n" },
    { text: "WARNING: Upgrade required for system compatibility.", yellow: true },
    { text: "Upgrading NFTs to Neo-Cybernetic Framework.\n\n" },
    { text: "Await further instructions from DRIFTERS command:" },
    { label: "[1] Twitter", url: "https://twitter.com/driftersEXE" },
    { label: "[2] Discord", url: "http://discord.gg/driftersEXE" },
    { label: "[3] Instagram", url: "https://instagram.com/driftersEXE" },
    { label: "[4] Youtube", url: "https://www.youtube.com/channel/UClZ4NVGrlk6ONj2o6w7eyuQ" },
    { label: "[5] TikTok", url: "https://www.tiktok.com/@driftersexe" },
    { text: "\nA BRIGHTER FUTURE AWAITS..." }
];

const RebrandingLandingPage = () => {
    const [loading, setLoading] = useState(true);
    const [flash, setFlash] = useState(true); // State to control flash animation
    const [dots, setDots] = useState('');

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false); // Set loading to false after 1 second
        }, 5000);

        return () => clearTimeout(timer); // Cleanup function to clear the timer
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false); // Set loading to false after 1 second
        }, 3000);

        // Animation for the flash
        const flashTimer = setInterval(() => {
            setFlash(prevFlash => !prevFlash); // Toggle flash
        }, 100); // Interval of 500ms for the flash animation

        return () => {
            clearTimeout(timer); // Cleanup function to clear the loading timer
            clearInterval(flashTimer); // Cleanup function to clear the flash animation timer
        };
    }, []);

    // Animate dots every 500ms
    useEffect(() => {
        const interval = setInterval(() => {
            setDots(prevDots => prevDots === '...' ? '' : prevDots + '.');
        }, 500);

        return () => clearInterval(interval); // Cleanup function to clear the interval
    }, []);

    useEffect(() => {
        if (!loading) {
            const lines = document.querySelectorAll('.line');
            let index = 0;

            const printLine = () => {
                if (index < lines.length) {
                    const line = lines[index];
                    const item = data[index];
                    if (item.text) {
                        line.textContent = item.text;
                    } else if (item.label && item.url) {
                        line.innerHTML = `${item.label}: <a href="${item.url}" target="_blank">@driftersEXE</a>`;
                    }
                    if (index === 2) {
                        line.textContent = line.textContent;
                        index++;
                        setTimeout(() => {
                            printLine(); // Move to the next line immediately after 5 seconds
                        }, 0);
                    }
                    else if (index === 4) {
                        line.textContent = line.textContent;
                        index++;
                        setTimeout(() => {
                            printLine(); // Move to the next line immediately after 5 seconds
                        }, 0);

                    } else {
                        index++;
                        setTimeout(printLine, 0); // Print each line after 1 second
                    }
                }
            };

            printLine();
        }
    }, [loading]);

    return (
        <div className="home">
            {loading && (
                <div className={`command-line-loader ${flash ? 'flash' : ''}`}>
                    <pre><span className="loader-prompt">[CRITICAL ERROR]</span></pre>
                    <pre><span className="loader-prompt">Collective cyber-consciousness at risk</span></pre>
                </div>
            )}
            {!loading && (
                <pre className="title"><span>SYSTEM ALERT [0x50L]</span></pre>)
            }
            {!loading && data.map((_, index) => (
                <pre key={index} className={`line ${data[index]?.yellow ? 'yellow-text' : ''}`}>{data[index]?.text}</pre>
            ))}
        </div>
    );
}

export default RebrandingLandingPage;
