import { React, useEffect, useState } from 'react';
import { Navbar, Header } from '../../components';
import CrewCard from './CrewCard';

import './Crew.css';
import crewJson from './crew.json';

function Crew() {

  const crewObject = crewJson.crew;
  const [currentStoryId, setCurrentStoryId] = useState(0);
  const [featuredDrifters, setFeaturedDrifters] = useState([]);

  return (
    <div className="crew__container">
      <Header name='crew'></Header>
      <div className="crew__list">
        {crewObject.map(e => {
          return <CrewCard
            drifterId={e.drifterId}
            collectionId={e.collectionId}
            name={e.name}
            title={e.title}
            favoriteAnime={e.favoriteAnime}
          />
        })}
      </div>
    </div>
  )
}

export default Crew
