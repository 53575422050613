import React from 'react'
import { Route, Routes, useLocation } from 'react-router-dom';
import { Home } from '../container';

function AnimatedRoutes() {
    const location = useLocation();

    return (
        <div>
            <Routes location={location} kye={location.pathname}>
                <Route path="/" element={
                    <div>
                        <Home />
                    </div>
                } />
            </Routes>
        </div>
    )
}

export default AnimatedRoutes
