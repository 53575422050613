import React from 'react';

import { Navbar, AnimatedRoutes } from './components';
import './App.css';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';

const App = () => (
  <Router>
    <AnimatedRoutes />
  </Router>
);

export default App;
