import React from 'react';

import './Header.css';

function Header(props){
  return <div className="app__header">
    {props.name}
  </div>
};

export default Header;
